import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const { REACT_APP_API_URL, REACT_APP_ENV } = process.env;

const host = window.location.host;
const isGreen = host.includes('green')
const isBlue = host.includes('blue')

let endpoint = REACT_APP_API_URL;

if(REACT_APP_ENV === "local" || !REACT_APP_ENV){
    endpoint = REACT_APP_API_URL;
} else if (REACT_APP_ENV === "prd"){
    if (isGreen){
        endpoint = 'https://' + 'api-' + 'green' + '.' + REACT_APP_API_URL

    } else if (isBlue){
        endpoint = 'https://' + 'api-' + 'blue' + '.' + REACT_APP_API_URL
    } else {
        endpoint = 'https://' + 'api' + '.' + REACT_APP_API_URL
    }

} else {
    if (isGreen){
        endpoint = 'https://' + 'api-' + 'green-'+ REACT_APP_ENV + '.' + REACT_APP_API_URL

    } else if (isBlue){
        endpoint = 'https://' + 'api-' + 'blue-' + REACT_APP_ENV + '.' + REACT_APP_API_URL
    } else {
        endpoint = 'https://' + 'api-' + REACT_APP_ENV + '.' + REACT_APP_API_URL
    }
}

const emptySplitApi = createApi({
    reducerPath: "api",
    tagTypes: [
        'Checkin'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${endpoint}`,
        credentials: "include",
        prepareHeaders: (headers, { getState }) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default emptySplitApi;
